<template>
  <ckeditor
    :editor="editor"
    :value="value"
    :config="editorConfig"
    :tag-name="tagName"
    :disabled="disabled"
    @ready="onReady"
    @input="event => $emit('input', event)"
  />
</template>
<script>
import UploadAdapter from '@/plugins/uploadAdapter';

let CKEditor;
let ClassicEditor;
CKEditor = require('@ckeditor/ckeditor5-vue2');
ClassicEditor = require('@/plugins/ckeditorv5_en');
export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    value: {
      type: String,
      required: false
    },
    tagName: {
      type: String,
      required: false,
      default: 'div'
    },
    disabled: {
      type: Boolean,
      required: false
    },
    uploadUrl: {
      type: String,
      required: false
    },
    config: {
      type: Object,
      required: false,
      default() {
        return {
          language: 'ko',
          extraPlugins: [this.uploader]
        };
      }
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: this.config
    };
  },
  methods: {
    uploader(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new UploadAdapter(loader);
      };
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());

      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new UploadAdapter(loader);
      };
    }
  }
};
</script>
<style>
.ck.ck-toolbar .ck.ck-toolbar__separator,
.mce-container {
  background: #c4c4c4 !important;
}

.ck.ck-content h2 {
  font-size: 1.2rem !important;
}

.ck.ck-content h3 {
  font-size: 1.115rem !important;
}

.ck.ck-content h4 {
  font-size: 1rem !important;
}

.ck.ck-content strong,
.ck.ck-content b {
  font-weight: bold !important;
}
</style>
